import { createContext, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import useRefMounted from "../hooks/useRefMounted";
import {
  getCompleteGetNext,
  getContent,
  getCourseDetail,
  getGetNext,
  getPreviousContent,
  setCompleted,
  setCompletedContent,
} from "../api/lib/course";
import { el } from "date-fns/locale";

const CourseContext = createContext(
  null,
  null,
  () => {},
  () => {},
  () => {},
  () => {}
);

export const CourseProvider = ({ children }) => {
  const [courseParams, setCourseParams] = useState();
  const [course, setCourse] = useState();

  const [courseContent, setCourseContent] = useState();
  const [courseCompleted, setCourseCompleted] = useState(false);
  const [contentFinished, setContentFinished] = useState(false);

  const [loadContent, setLoadContent] = useState();
  const [updateURL, setUpdateURL] = useState(false);
  const [newURL, setNewUrl] = useState();

  const [previous, setPrevious] = useState(false);
  const [next, setNext] = useState(false);

  const [completed, setCompleted] = useState(false);

  const [hasShownFinished, setHasShownFinished] = useState(false);

  const isMountedRefGetCourse = useRefMounted();
  const isMountedRefGetContent = useRefMounted();
  const isMountedRefGetNext = useRefMounted();
  const isMountedRefGetPrevious = useRefMounted();
  const navigate = useNavigate();

  useEffect(() => {
    if (updateURL) {
      window.history.pushState({}, undefined, `${newURL}`);
      setUpdateURL(false);
    }
  }, [updateURL, newURL]);

  useEffect(() => {
    // console.log("setCourseValues ", courseParams);
  }, [courseParams]);

  const setCourseValues = (courseId, moduleId, contentId) => {
    setCourseParams({ courseId, moduleId, contentId });
  };

  const getCourse = useCallback(async () => {
    if (courseParams) {
      // console.log("COURSEPARAMS", courseParams);
      try {
        const response = await getCourseDetail(courseParams.courseId);

        if (isMountedRefGetCourse.current) {
          setCourse(response.data);
        }
      } catch (err) {
        //console.log("ERROR GET COURSE", err);
        if (err.response.status === 403) {
          navigate("/subscription");
        }
      }
    }
  }, [courseParams, isMountedRefGetCourse]);

  const getCourseContent = useCallback(async () => {
    try {
      const response = await getContent(
        courseParams.courseId,
        courseParams.moduleId,
        courseParams.contentId
      );
      // console.log("COURSE CONTENT", response.data.content);
      if (isMountedRefGetContent.current) {
        // Armazena a página atual no sessionStorage quando a rota mudar
        sessionStorage.setItem(
          "lastVisitedPage",
          `/dashboards/lecture/${courseParams.courseId}/${courseParams.moduleId}/${courseParams.contentId}`
        );
        setNewUrl(
          `/dashboards/lecture/${courseParams.courseId}/${courseParams.moduleId}/${courseParams.contentId}`
        );
        setUpdateURL(true);
        setCourseContent(response.data.content);
        setContentFinished(response.data.content_finished);

        setTimeout(() => {
          window.scrollTo(0, 0);
          //console.log("Scrolling");
        }, 0);
      }
    } catch (err) {
      // console.log("ERROR GET CONTENT", err);
      if (err.response.status === 403) {
        navigate("/subscription");
      }
    }
  }, [courseParams, isMountedRefGetContent]);

  const getPreviousCourseContent = useCallback(async () => {
    try {
      const response = await getPreviousContent(
        courseParams.courseId,
        courseParams.moduleId,
        courseParams.contentId
      );

      if (isMountedRefGetPrevious.current) {
        sessionStorage.setItem(
          "lastVisitedPage",
          `/dashboards/lecture/${courseParams.courseId}/${courseParams.moduleId}/${courseParams.contentId}`
        );
        setNewUrl(
          `/dashboards/lecture/${courseParams.courseId}/${courseParams.moduleId}/${courseParams.contentId}`
        );
        setUpdateURL(true);

        setCourseContent(response.data.content);
        setCourseValues(
          courseParams.courseId,
          response.data.content.moduleId,
          response.data.content.contentId
        );
        setContentFinished(response.data.content_finished);

        setTimeout(() => {
          window.scrollTo(0, 0);
          //console.log("Scrolling");
        }, 0);
      }
    } catch (err) {
      // console.log("ERROR GET CONTENT", err);
      if (err.response.status === 403) {
        navigate("/subscription");
      }
    }
  }, [courseParams, isMountedRefGetPrevious]);

  const getNextCourseContent = useCallback(async () => {
    try {
      const response = await getGetNext(
        courseParams.courseId,
        courseParams.moduleId,
        courseParams.contentId
      );

      if (isMountedRefGetNext.current) {
        sessionStorage.setItem(
          "lastVisitedPage",
          `/dashboards/lecture/${courseParams.courseId}/${courseParams.moduleId}/${courseParams.contentId}`
        );
        setNewUrl(
          `/dashboards/lecture/${courseParams.courseId}/${courseParams.moduleId}/${courseParams.contentId}`
        );
        setUpdateURL(true);
        if (response.data.content == "finished") {
          navigate(`/dashboards/course`);
        } else {
          setCourseContent(response.data.content);

          setCourseValues(
            courseParams.courseId,
            response.data.content.moduleId,
            response.data.content.contentId
          );
          setContentFinished(response.data.content_finished);

          setTimeout(() => {
            window.scrollTo(0, 0);
            //console.log("Scrolling");
          }, 0);
        }
      }
    } catch (err) {
      // console.log("ERROR GET CONTENT", err);
      if (err.response.status === 403) {
        navigate("/subscription");
      }
    }
  }, [courseParams, isMountedRefGetNext]);

  const setCompleteContent = useCallback(async () => {
    try {
      const response = await setCompletedContent(
        courseParams.courseId,
        courseParams.moduleId,
        courseParams.contentId
      );

      if (isMountedRefGetNext.current) {
        sessionStorage.setItem(
          "lastVisitedPage",
          `/dashboards/lecture/${courseParams.courseId}/${courseParams.moduleId}/${courseParams.contentId}`
        );
        setNewUrl(
          `/dashboards/lecture/${courseParams.courseId}/${courseParams.moduleId}/${courseParams.contentId}`
        );
        setUpdateURL(true);

        setCourseContent(response.data.content);
        setCourseValues(
          courseParams.courseId,
          response.data.content.moduleId,
          response.data.content.contentId
        );
        //console.log("setCompleteContent", response.data.course_completed);
        setCourseCompleted(response.data.course_completed);
        setHasShownFinished(response.data.has_show_finished,);
        setContentFinished(response.data.content_finished);
        //console.log("Content Finished", response.data.content_finished);
      }
    } catch (err) {
      if (err.response.status === 403) {
        navigate("/subscription");
      }
    }
  }, [courseParams, isMountedRefGetNext]);

  const handleLoad = () => {
    setLoadContent(true);
  };

  const handleNext = () => {
    setNext(true);
  };

  const handlePrevious = () => {
    setPrevious(true);
  };

  const handleCompleted = () => {
    setCompleted(true);
  };

  useEffect(() => {
    getCourse();
  }, [getCourse, next, previous]);

  useEffect(() => {
    if (loadContent) {
      getCourseContent();
      setLoadContent(false);
    }
  }, [getCourseContent, loadContent]);

  useEffect(() => {
    if (completed) {
      setCompleteContent();
      setCompleted(false);
    }
  }, [setCompleteContent, completed]);

  useEffect(() => {
    if (next) {
      getNextCourseContent();
      setNext(false);
    }
  }, [getNextCourseContent, next]);

  useEffect(() => {
    if (previous) {
      getPreviousCourseContent();
      setPrevious(false);
    }
  }, [getPreviousCourseContent, previous]);

  return (
    <CourseContext.Provider
      value={{
        course,
        courseCompleted,
        hasShownFinished,
        courseContent,
        contentFinished,
        setCourseValues,
        handleLoad,
        handleNext,
        handlePrevious,
        handleCompleted,
      }}
    >
      {children}
    </CourseContext.Provider>
  );
};

export default CourseContext;
