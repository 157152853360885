import SmartToyTwoToneIcon from "@mui/icons-material/SmartToyTwoTone";
import SchoolTwoToneIcon from "@mui/icons-material/SchoolTwoTone";
import SportsScoreTwoToneIcon from "@mui/icons-material/SportsScoreTwoTone";
import EmojiEventsTwoToneIcon from "@mui/icons-material/EmojiEventsTwoTone";

import StarsTwoToneIcon from "@mui/icons-material/StarsTwoTone";
import IconDiscord from "../../../../components/IconDiscord";
import HelpTwoToneIcon from "@mui/icons-material/HelpTwoTone";
import SupportAgentTwoToneIcon from '@mui/icons-material/SupportAgentTwoTone';


const menuItems = [
  {
    heading: "",
    items: [
      {
        name: "LEARN",
        icon: SchoolTwoToneIcon,
        link: "",
        open: true,
        items: [
          {
            open: true,
            active: true,
            name: "Dashboards",
            icon: SmartToyTwoToneIcon,
            link: "/dashboards",
          },
          {
            name: "My Learning",
            link: "dashboards/mylearning",
            icon: StarsTwoToneIcon,
          },
          {
            name: "Cursos",
            icon: SchoolTwoToneIcon,
            link: "/dashboards/course",
          },
        ],
      },
      {
        name: "CYBERARENA",
        icon: SportsScoreTwoToneIcon,
        link: "",
        open: true,
        items: [
          {
            name: "Desafios",
            open: true,
            icon: SportsScoreTwoToneIcon,
            link: "/dashboards/challenge",
          },
        ],
      },
      {
        name: "EVENTS",
        icon: EmojiEventsTwoToneIcon,
        link: "",
        items: [
          {
            name: "CTF",
            link: "/ctfevents/event",
          },
          {
            name: "Team",
            link: "/ctfevents/team",
          },
        ],
      },
      {
        name: "HELP",
        icon: HelpTwoToneIcon,
        link: "",
        items: [
          {
            name: "Discord",
            icon: IconDiscord,
            link: "https://discord.gg/BHMC5NsPMp",
            external: true,
          },
          {
            name: "Suporte",
            icon: SupportAgentTwoToneIcon,
            link: "/account/support",
          },
        ],
      },
    ],
  },
];

export default menuItems;
