import React, { useState } from "react";
import {
  Button,
  Container,
  FormControlLabel,
  Grid,
  Rating,
  TextField,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { courseReview } from "../../../../../api/lib/course";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const LectureFinished = ({ courseId }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [rating, setRating] = useState(3);
  const [comment, setComment] = useState("");
  const navigate = useNavigate();

  

  const onSubmit = (event) => {
    event.preventDefault();
    const formdata = new FormData(event.target);
    try {
      let data = { rating, comment: formdata.get("comment") };

      const response = courseReview(courseId, data);
      setComment("");
      navigate("/dashboards/");
    } catch (err) {
      // console.log("ERROR", err);
    }
  };

  return (
    <form noValidate onSubmit={onSubmit}>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        style={{ minHeight: "80vh" }}
      >
        <Grid item>
          <Typography sx={{ typography: { sm: "h1", xs: "h4" } }}>
            🙌{" "}
            {t(
              "Congratulations! You have completed the last module of the course."
            )}
          </Typography>
        </Grid>
        <Grid item>
          <Typography sx={{ typography: { sm: "h1", xs: "h4" } }}>
            {t("Leave your review about the Course.")}
          </Typography>
        </Grid>
        <Grid item>
          <br></br>
          <Rating
            size="large"
            name="rating"
            value={rating}
            precision={1}
            onChange={(_, value) => {
              setRating(value);
            }}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={6} xl={4}>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            multiline
            rows={6}
            id="Review"
            label="Review"
            name="comment"
            autoFocus
            sx={{ width: 400 }}
            onChange={(event) => setComment(event.target.value)}
            value={comment}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={6} xl={4}>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            sx={{ width: 400 }}
          >
            {t("Submit")}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default LectureFinished;
