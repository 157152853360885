import { useRef, useState } from "react";
import useAuth from "../../../../hooks/useAuth";
import { useNavigate, useLocation } from "react-router-dom";

import {
  Box,
  alpha,
  Typography,
  styled,
  useTheme,
  Tooltip,
  Chip,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import UserAvatar from "../../../../components/UserAvatar";

const MenuUserBox = styled(Box)(
  ({ theme }) => `
    background: ${theme.colors.alpha.black[5]};
    padding: ${theme.spacing(2)};
`
);

const UserBoxText = styled(Box)(
  ({ theme }) => `
    text-align: left;
    padding-left: ${theme.spacing(1)};
`
);

const UserBoxLabel = styled(Typography)(
  ({ theme }) => `
    font-weight: ${theme.typography.fontWeightBold};
    color: ${theme.sidebar.menuItemColor};
    display: block;

    &.popoverTypo {
      color: ${theme.palette.secondary.main};
    }
`
);

const UserBoxDescription = styled(Typography)(
  ({ theme }) => `
    color: ${alpha(theme.sidebar.menuItemColor, 0.6)};

    &.popoverTypo {
      color: ${theme.palette.secondary.light};
    }
`
);

function SidebarTopSection() {
  const { t } = useTranslation();
  const theme = useTheme();

  const navigate = useNavigate();
  const location = useLocation();
  const { user, logout } = useAuth();

  const ref = useRef(null);
  const [isOpen, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = async () => {
    try {
      handleClose();
      await logout();
      navigate("/");
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Box
      sx={{
        textAlign: "center",
         position: "relative",
      }}
    >
      <UserAvatar name={user.username} imageUrl={user.avatar} />
      

      <Typography
        variant="h4"
        sx={{
          color: `${theme.colors.alpha.trueWhite[100]}`,
        }}
      >
        {user.username}{" "}
        {user.subscription?.map((item, id) => {
          if (item.subs === "Free") {
            return (
              <Tooltip
                key={id}
                title={
                  "FREE - Adquira uma subscrição para liberar os recursos da plataforma"
                }
              >
                <Chip
                  label={item.emoji}
                  variant="outlined"
                  color="error"
                  onClick={() => {
                    navigate("/subscription");
                  }}
                />
              </Tooltip>
            );
          } else {
            return (
              <Tooltip key={id} title={item.subs}>
                <Chip label={item.emoji} variant="outlined" color="info" />
              </Tooltip>
            );
          }
        })}
      </Typography>
      <Typography
        variant="subtitle1"
        sx={{
          color: `${theme.colors.alpha.trueWhite[70]}`,
        }}
      >
        {user.level_title}
      </Typography>
     
    </Box>
  );
}

export default SidebarTopSection;
