import axiosClient from "../apiClient";



export async function  getChalls(uuid){
   
    return axiosClient.get(`/api/chall/`);
   
}

export async function  getChallDetail(uuid){
   
    return axiosClient.get(`/api/chall/${uuid}/`);
}


export async function  getChallCategories(uuid){
   
    return axiosClient.get(`/api/categ/`);
   
}

export async function  getChallSearch(query){
   
    return axiosClient.get(`/api/chall/search?query=${query}`);
   
}

export async function  getChallFlags(uuid){
   
    return axiosClient.get(`/api/flags/${uuid}/`);
   
}

export async function  challReview(uuid,data){
   
    return axiosClient.post(`/api/chall/${uuid}/review/`,data);
   
}

export async function  setAccepted(uuid){
   
    return axiosClient.post(`/api/chall/${uuid}/Accepted/`);
   
}


export async function  getFlagHint(uuid,flag_id){
   
    return axiosClient.get(`/api/flags/${flag_id}/hint?ch=${uuid}`);
   
}

export async function  getWkt(uuid){
   
    return axiosClient.get(`/api/wkt/${uuid}/`);
   
}


export async function  getDownloadWKT(uuid){
   
    return axiosClient.get(`/api/wkt/${uuid}/download/`);
   
}


export async function  getChallengeCertifiedRequest(uuid){
   
    return axiosClient.get(`/api/chall/${uuid}/cert_request/`);
   
}
