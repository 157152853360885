import { Box, alpha, lighten, useTheme, Drawer } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Outlet, useNavigate } from "react-router-dom";
import Sidebar from "./Sidebar";
import Header from "./Header";
import { SidebarContext } from "../../contexts/SidebarContext";
import { useContext, useEffect, useState } from "react";
import MuiAppBar from "@mui/material/AppBar";

const drawerWidth = 290;



const Main = styled(Box, { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    // padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: `${drawerWidth}px`,
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const ExtendedSidebarLayout = () => {
  const theme = useTheme();
  const { sidebarToggle, closeSidebar } =
    useContext(SidebarContext);



    // useEffect(() => {
    //   //disable day 10/08/2024
    //   if (new Date() <= new Date("2024-08-10 18:00")) {
       
    //    setCtfTitle("3K");
    //          setCtfUrl("/ctfevents/event/detail/32249222-ctf-xtr-3k");
    //          setCtfImage("https://st4tic.s3.bhs.io.cloud.ovh.net/media/event_images/popup_ctf_3k.jpg");
    //          setTimeout(() => {
    //            setOpen(true);
    //          }, 10000);
            
    //         }
    // }, []);
  

  return (
    <Box
      sx={{
        flex: 1,
        height: "100%",
        ".MuiPageTitle-wrapper": {
          background:
            theme.palette.mode === "dark"
              ? theme.colors.alpha.trueWhite[5]
              : theme.colors.alpha.white[50],
          marginBottom: `${theme.spacing(4)}`,
          boxShadow:
            theme.palette.mode === "dark"
              ? `0 1px 0 ${alpha(
                  lighten(theme.colors.primary.main, 0.7),
                  0.15
                )}, 0px 2px 4px -3px rgba(0, 0, 0, 0.2), 0px 5px 12px -4px rgba(0, 0, 0, .1)`
              : `0px 2px 4px -3px ${alpha(
                  theme.colors.alpha.black[100],
                  0.1
                )}, 0px 5px 12px -4px ${alpha(
                  theme.colors.alpha.black[100],
                  0.05
                )}`,
        },
      }}
    >
      <AppBar position="fixed" open={sidebarToggle}>
        <Header />
      </AppBar>

      <Drawer
        sx={{
          width: drawerWidth,

          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
        anchor="left"
        open={sidebarToggle}
        onClose={closeSidebar}
        variant="persistent"
      >
        <Sidebar />
      </Drawer>
      <Main
        open={sidebarToggle}
        sx={{
          position: "relative",
          zIndex: 5,
          display: "block",
          flex: 1,
          pt: `${theme.header.height}`,
         
        }}
      >
        <Box display="block">
          <Outlet />
        </Box>
        
      </Main>
    </Box>
  );
};

export default ExtendedSidebarLayout;
